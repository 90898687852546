import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { prepareHeaders } from './utils';
import {
    Account,
    transformAccountApiResponse,
    transformToExternalUserPatch,
    transformUser,
    User,
    UserPatch,
} from './types';

const TagType = {
    USERS: 'Users',
} as const;

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.USER_ADMIN_SERVICE, prepareHeaders }),
    tagTypes: [TagType.USERS],
    endpoints: (build) => ({
        fetchUser: build.query<User, string>({
            query: (userId) => `/users/${userId}`,
            providesTags: (_result, _error, id) => [{ type: TagType.USERS, id }],
            transformResponse: (response) => {
                return transformUser(response);
            },
        }),

        fetchAccounts: build.query<Account[], string>({
            query: (userId) => `/users/${userId}/accounts`,
            transformResponse: (response) => {
                return transformAccountApiResponse(response).accounts;
            },
        }),

        patchUser: build.mutation<any, UserPatch>({
            queryFn: async (userPatch, _api, _options, baseQuery) => {
                try {
                    const patch = transformToExternalUserPatch(userPatch);
                    const result = await baseQuery({ url: `/users/${userPatch.id}`, method: 'PATCH', body: patch });

                    // delay to ensure that change has propagated to users table in auth server
                    // it would be cleaner, to poll the /userinfo endpoint, but currently the data there is not
                    // updated (since the locale in that endpoint is tied to the authorization)
                    await delay(500);

                    return result;
                } catch (err) {
                    if (err instanceof Error) {
                        return { error: { status: 500, data: err.message } };
                    } else {
                        return { error: { status: 500, data: `Unknown error occurred: ${JSON.stringify(err)}` } };
                    }
                }
            },
            invalidatesTags: (_result, _error, arg) => [{ type: TagType.USERS, id: arg.id }],
        }),

        activateOtp: build.mutation<any, string>({
            query: (userId) => ({ url: `/users/${userId}/mfa/otp/activate`, method: 'POST' }),
            invalidatesTags: (_result, _error, arg) => [{ type: TagType.USERS, id: arg }],
        }),

        deactivateOtp: build.mutation<any, string>({
            query: (userId) => ({ url: `/users/${userId}/mfa/otp/deactivate`, method: 'POST' }),
            invalidatesTags: (_result, _error, arg) => [{ type: TagType.USERS, id: arg }],
        }),
    }),
});

export const {
    useFetchUserQuery,
    useFetchAccountsQuery,
    usePatchUserMutation,
    useActivateOtpMutation,
    useDeactivateOtpMutation,
} = usersApi;

// --------------------------------------
// Utils
const delay = (delayInMs: number) => new Promise((resolve) => setTimeout(resolve, delayInMs));
