import { useEffect } from 'react';

interface UseCloseDialogOnEscapeArgs {
    isShowDialog: boolean;
    closeDialog: () => void;
}

export function useCloseDialogOnEscape({ isShowDialog, closeDialog }: UseCloseDialogOnEscapeArgs) {
    useEffect(() => {
        if (!isShowDialog) {
            return;
        }

        function keyDownHandler(e: globalThis.KeyboardEvent) {
            if (isShowDialog && e.key === 'Escape') {
                e.preventDefault();
                closeDialog();
            }
        }

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [isShowDialog, closeDialog]);
}
