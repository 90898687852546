import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';

export const switchAccountApi = createApi({
    reducerPath: 'switchAccountApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.login.authority, credentials: 'include' }),
    endpoints: (build) => ({
        switchAccount: build.mutation<undefined, string>({
            query: (accountId) => ({
                url: `/switch-account/${accountId}`,
                method: 'POST',
            }),
        }),
    }),
});

export const { useSwitchAccountMutation } = switchAccountApi;
