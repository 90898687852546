import { lazy, Suspense, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import './App.css';
import {
    getSessionExpiredAcknowledged,
    hideSessionExpiredDialog,
    selectShowSettingsDialog,
    showSettingsDialog,
} from './app/appSlice';
import { UserSettingsDialogWrapper } from './app/components/UserSettingsDialog/UserSettingsDialogWrapper';
import { getDisplayMessages, getLocale } from '../configuration/lang/langSlice';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { isRunningInIframe } from '../configuration/login/login';
import { useAppDispatch, useAppSelector } from '../configuration/setup/hooks';
import { getUserProfile, isUserSessionExpired } from '../configuration/login/loginSlice';
import { DEFAULT_LOCALE } from '../configuration/lang/lang';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';

const SessionExpiredDialog = lazy(() =>
    import('@rio-cloud/rio-session-expired-info').then(({ SessionExpiredDialog }) => ({
        default: SessionExpiredDialog,
    }))
);

const PopoverContent = lazy(() =>
    import('./app/components/PopoverContent/PopoverContent').then(({ PopoverContent }) => ({ default: PopoverContent }))
);

const App = () => {
    const languageData = useAppSelector(getDisplayMessages);
    const showSessionExpired = useAppSelector(
        (state) => isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state)
    );
    const isShowSettingsDialog = useAppSelector(selectShowSettingsDialog);
    const userLocale = useAppSelector(getLocale);
    const userProfile = useAppSelector(getUserProfile);

    const dispatch = useAppDispatch();

    const hideSessionDialog = () => dispatch(hideSessionExpiredDialog());

    useEffect(() => {
        const dispatchShowSettingsDialog = (tabIndex = 0) => dispatch(showSettingsDialog(tabIndex));

        const settingsDialogRegExp = /^#\/?show(?:New)?SettingsDialog/;
        if (settingsDialogRegExp.test(window.location.hash)) {
            if (window.location.hash.includes('?tab=2')) {
                dispatchShowSettingsDialog(2);
            } else {
                dispatchShowSettingsDialog(0);
            }
        }
    }, [dispatch]);

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={languageData}>
            {!isRunningInIframe() && (
                <Suspense>
                    <SessionExpiredDialog locale={userLocale} onClose={hideSessionDialog} show={showSessionExpired} />
                </Suspense>
            )}
            {/* empty ApplicationLayout calls UiKit init method */}
            <ApplicationLayout />
            <NotificationsContainer stacked />
            {isShowSettingsDialog ? (
                <UserSettingsDialogWrapper />
            ) : (
                <Suspense>
                    <PopoverContent />
                </Suspense>
            )}
            <div style={{ display: 'none' }} id={'capability-monitoring-auth-server'}>
                {userProfile?.username}
            </div>
        </IntlProvider>
    );
};

export default App;
