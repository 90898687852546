import { IFrameWindow, UserInfo } from '../../configuration/types';
import { User } from '../app/services/types';

export const IFRAME_SHOW_DIALOG = 'IFRAME_SHOW_DIALOG';
export const IFRAME_PROFILE_CHANGED = 'IFRAME_PROFILE_CHANGED';
export const IFRAME_LANGUAGE_CHANGED = 'IFRAME_LANGUAGE_CHANGED';
export const IFRAME_PAGE_REDIRECT = 'IFRAME_PAGE_REDIRECT';
export const IFRAME_LOGOUT = 'IFRAME_LOGOUT';

interface IframeShowDialogAction {
    type: typeof IFRAME_SHOW_DIALOG;
    payload: {
        selectedTab: number;
    };
}

interface IframeProfileChangedAction {
    type: typeof IFRAME_PROFILE_CHANGED;
    payload: UserInfo;
}

interface IframeLanguageChangedAction {
    type: typeof IFRAME_LANGUAGE_CHANGED;
    payload: string;
}

interface IframePageRedirectAction {
    type: typeof IFRAME_PAGE_REDIRECT;
    payload: string;
}

// only still here for compatibility reasons
interface IframeLogoutAction {
    type: typeof IFRAME_LOGOUT;
}

export const showDialog = (selectedTab = 0): IframeShowDialogAction => ({
    type: IFRAME_SHOW_DIALOG,
    payload: {
        selectedTab: selectedTab,
    },
});

export const profileChanged = (userInfo: UserInfo): IframeProfileChangedAction => ({
    type: IFRAME_PROFILE_CHANGED,
    payload: userInfo,
});

export const languageChanged = (locale: string): IframeLanguageChangedAction => ({
    type: IFRAME_LANGUAGE_CHANGED,
    payload: locale,
});

export const pageRedirect = (url: string): IframePageRedirectAction => ({
    type: IFRAME_PAGE_REDIRECT,
    payload: url,
});

export const pageLogout = (): IframeLogoutAction => ({
    type: IFRAME_LOGOUT,
});

export type IframeActionType =
    | IframeShowDialogAction
    | IframeProfileChangedAction
    | IframeLanguageChangedAction
    | IframePageRedirectAction
    | IframeLogoutAction;

const isUserInfo = (obj: any): obj is UserInfo => {
    return typeof obj.firstName === 'string' && typeof obj.lastName === 'string' && typeof obj.username === 'string';
};

export const sendMessageToIFrameWrapper = (action: IframeActionType) => {
    let payloadToSend = {};

    switch (action.type) {
        case IFRAME_SHOW_DIALOG: {
            payloadToSend = { type: IFRAME_SHOW_DIALOG, payload: action.payload };
            break;
        }
        case IFRAME_PROFILE_CHANGED: {
            if (!isUserInfo(action.payload)) {
                break;
            }
            payloadToSend = {
                type: IFRAME_PROFILE_CHANGED,
                user: action.payload,
            };
            break;
        }
        case IFRAME_LANGUAGE_CHANGED: {
            payloadToSend = {
                type: IFRAME_LANGUAGE_CHANGED,
                locale: action.payload,
            };
            break;
        }
        case IFRAME_PAGE_REDIRECT: {
            payloadToSend = {
                type: IFRAME_PAGE_REDIRECT,
                payload: action.payload,
            };
            break;
        }
        case IFRAME_LOGOUT: {
            payloadToSend = {
                type: IFRAME_LOGOUT,
            };
        }
    }
    (window as IFrameWindow).parentIFrame.sendMessage(payloadToSend);
    // for compatibility with older user-menu-component
    window.parent.postMessage(payloadToSend, '*');
};

export const sendComponentLoaded = () => {
    (window as IFrameWindow).parentIFrame.sendMessage(
        `Loaded iframe [${(window as IFrameWindow).parentIFrame.getId()}].`
    );
};

export const sendUserInfoToEmbeddingComponent = (user: User, userName: string | undefined) => {
    if ('parentIFrame' in window) {
        const userInfo: UserInfo = {
            firstName: user.firstName,
            lastName: user.lastName,
            username: userName ? userName : '',
        };
        sendMessageToIFrameWrapper(profileChanged(userInfo));
    }
};

export const sendLocaleChangeToEmbeddingComponent = (locale: string) => {
    if ('parentIFrame' in window) {
        sendMessageToIFrameWrapper(languageChanged(locale));
    }
};
