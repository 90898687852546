import * as React from 'react';
import { PropsWithChildren } from 'react';

export const ErrorBanner = ({ children }: PropsWithChildren) => (
    <div className="alert alert-dismissible alert-danger">
        <div className="display-flex">
            <span className="text-color-danger text-size-h4 margin-right-10 rioglyph rioglyph rioglyph-error-sign" />
            <div className="max-width-350">{children}</div>
        </div>
    </div>
);
