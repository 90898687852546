import { createSelector, createSlice } from '@reduxjs/toolkit';
import { accessToken } from './accessToken';
import { RootState } from '../setup/store';
import { AccessToken, IdToken } from '../types';

// interface AccessTokenState {
//     accessToken: AccessToken;
//     idToken: IdToken | null;
// }

interface TokenHandlingState {
    accessToken: AccessToken;
    idToken: IdToken | null;
}

const initialState: TokenHandlingState = {
    accessToken: accessToken.getAccessToken(),
    idToken: null,
};

const tokenHandlingSlice = createSlice({
    name: 'tokenHandling',
    initialState,
    reducers: {
        accessTokenStored: (state, action) => {
            state.accessToken = action.payload;
        },
        idTokenStored: (state, action) => {
            state.idToken = action.payload;
        },
    },
});

export const tokenHandlingReducer = tokenHandlingSlice.reducer;

export const { accessTokenStored, idTokenStored } = tokenHandlingSlice.actions;

export const getAccessToken = (state: RootState) => state.tokenHandling.accessToken;
export const getIdToken = (state: RootState) => state.tokenHandling.idToken;

export const getTenant = (state: RootState) => state.tokenHandling.idToken?.tenant;

export const getIdTokenInfo = createSelector([getIdToken], (idToken) => {
    return idToken
        ? {
              firstName: idToken.given_name,
              lastName: idToken.family_name,
              userName: idToken.username,
              subject: idToken.sub,
          }
        : {
              firstName: 'A problem occurred',
              lastName: '',
              userName: 'Data could not be loaded',
              subject: '',
          };
});
