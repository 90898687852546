import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getUserProfileId } from '../../../../configuration/login/loginSlice';
import { useFetchAccountsQuery } from '../../services/usersApi';
import { useSwitchAccountMutation } from '../../services/switchAccountApi';
import { Account } from '../../services/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomSpinner } from '../../common';
import { triggerPageReload } from './switchAccountUtils';
import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import { ErrorBanner } from '../../common/ErrorBanner';
import { ErrorStatusCodes } from '../../common/ErrorStatusCodes';

const activeAccountsFirst = (a: Account, b: Account) => Number(b.active) - Number(a.active);

const activeAndFilteredAccounts = (searchValue: string) => (account: Account) => {
    if (searchValue) {
        if (account.active) {
            return true;
        }
        return account.name.toLowerCase().includes(searchValue.toLowerCase());
    } else {
        return true;
    }
};

const getSelectedClass = (isSelected: boolean) =>
    isSelected ? 'border-color-highlight bg-highlight-decent pointer-events-none z-index-1' : 'bg-decent';

const getLockedClass = (isLocked: boolean) => (isLocked ? 'pointer-events-none' : '');

export const Accounts = () => {
    const [searchValue, setSearchValue] = useState('');
    const [accountIdToSwitchTo, setAccountIdToSwitchTo] = useState<string | undefined>(undefined);

    const userProfileId = useAppSelector(getUserProfileId);

    const { data: accounts, isLoading } = useFetchAccountsQuery(userProfileId!);
    const [switchAccount, { isLoading: isUpdating, isError: isUpdateError }] = useSwitchAccountMutation();

    const sortedAccounts = accounts
        ?.filter(activeAndFilteredAccounts(searchValue))
        .slice()
        .sort((a, b) => activeAccountsFirst(a, b));

    const showSearch = searchValue || (sortedAccounts ? sortedAccounts.length > 4 : false);

    const handleSwitchAccount = (e: ChangeEvent, accountId: string) => {
        e.preventDefault();
        setAccountIdToSwitchTo(accountId);
        switchAccount(accountId)
            .unwrap()
            .then(triggerPageReload)
            .catch(() => {
                console.error(`Error when switching account. Target account: ${accountId}`);
            });
    };

    return isLoading || accounts === undefined ? (
        <CustomSpinner />
    ) : (
        <>
            <div className="form-group" data-cy={'switch-account-content'}>
                <label className="control-label">
                    {accounts.length === 1 ? (
                        <FormattedMessage id="intl-msg:settingsDialog.tab.accounts.text.current" />
                    ) : (
                        <div>
                            <FormattedMessage id="intl-msg:settingsDialog.tab.accounts.label" />
                            <span>{' - '}</span>
                            <FormattedMessage
                                id="intl-msg:settingsDialog.tab.accounts.label.prompt"
                                defaultMessage="select to switch"
                            />
                        </div>
                    )}
                </label>
                <div className="form-group">
                    {showSearch && <AccountSearch value={searchValue} onChange={(value) => setSearchValue(value)} />}
                </div>
            </div>
            {sortedAccounts && sortedAccounts.length > 0 && (
                <div className="max-height-300 margin-bottom-15 overflow-auto padding-y-1">
                    {sortedAccounts.map((account, idx) => {
                        let wrapperClassName = ''; // used to prevent tooltip from showing

                        let roundedClass = '';
                        if (idx === 0) {
                            roundedClass = 'rounded-top';
                        } else if (idx === sortedAccounts.length - 1) {
                            roundedClass = 'rounded-bottom';
                        }

                        if (isUpdating) {
                            wrapperClassName = 'pointer-events-none';
                        }

                        const isLocked = account.lifecycleState === 'locked';
                        const isAccountUpdating = accountIdToSwitchTo === account.id && isUpdating;

                        return (
                            <div className={wrapperClassName} key={account.id}>
                                <label
                                    className={`radio padding-15 margin-top--1 border ${getSelectedClass(account.active)} ${roundedClass} ${getLockedClass(isLocked)}`}
                                    tabIndex={0}
                                    key={account.id}
                                >
                                    <input
                                        type="radio"
                                        name="stackedRadioBoxes"
                                        value=""
                                        checked={account.active}
                                        onChange={account.active ? () => {} : (e) => handleSwitchAccount(e, account.id)}
                                    />
                                    <div className="radio-text-wrapper display-flex align-items-center">
                                        <span className="radio-text" />
                                        <div className="margin-left-5 margin-right-5">
                                            <div className="">
                                                <div className="display-flex align-items-center">
                                                    {isLocked && (
                                                        <span
                                                            className="rioglyph rioglyph-lock margin-right-5"
                                                            aria-label="locked-icon"
                                                        />
                                                    )}
                                                    <div
                                                        className={`max-width-300 ellipsis-1 ${account.active ? 'text-medium' : ''}`}
                                                    >
                                                        {account.name}
                                                    </div>
                                                    <div
                                                        className="btn btn-xs btn-muted btn-icon-only margin-left-auto btn-loading"
                                                        style={{
                                                            visibility: isAccountUpdating ? 'visible' : 'hidden',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        );
                    })}
                </div>
            )}
            {isUpdateError && (
                <ErrorBanner>
                    <strong className="text-size-16 margin-right-5">Error!</strong>
                    <ErrorStatusCodes errors={[]} />
                    <div>
                        <FormattedMessage
                            id={'intl-msg:settingsDialog.tab.accounts.error.switchAccount'}
                            defaultMessage={'Could not switch account.'}
                        />
                    </div>
                </ErrorBanner>
            )}
        </>
    );
};

interface AccountSearchProps {
    value: string;
    onChange: (
        newValue: string,
        event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLSpanElement>
    ) => void;
}

const AccountSearch = React.memo(({ value, onChange }: AccountSearchProps) => {
    const intl = useIntl();

    return (
        <div className={'input-group flex-1-0'}>
            <span className={'input-group-addon'}>
                <span className={'rioglyph rioglyph-search'} aria-hidden={'true'} />
            </span>
            <ClearableInput
                value={value}
                onChange={onChange}
                placeholder={intl.formatMessage({
                    id: 'intl-msg:settingsDialog.tab.accounts.search.placeholder',
                })}
            />
        </div>
    );
});
