import { FC, ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { PersonalData } from '../PersonalDataContainer/PersonalData';
import { selectTab, selectTabIndex } from '../../appSlice';
import { Accounts } from '../AccountsContainer/Accounts';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { LegalInfo } from '../LegalInfoContainer/LegalInfo';
import { LoginAndSecurity } from '../LoginAndSecurity/LoginAndSecurity';
import { UserInNavbar } from './UserInNavbar';
import { getUserProfileId } from '../../../../configuration/login/loginSlice';
import { useFetchAccountsQuery, useFetchUserQuery } from '../../services/usersApi';
import Spinner from '@rio-cloud/rio-uikit/Spinner';

interface Tab {
    title: ReactElement;
    subTitle: ReactElement;
    icon: string;
    content: ReactElement;
}

interface BodyProps {
    tabs: Array<Tab>;
    setTabIndex: (n: number) => void;
    tabIndex: number;
}

const UserSettingsBody = (props: BodyProps) => {
    const { tabs, setTabIndex, tabIndex } = props;

    const renderTabLink = (tab: Tab, index: number, setTabIndex: (n: number) => void, tabIndex: number) => {
        const activeClass = index === tabIndex ? 'menu-item active' : 'menu-item';

        return (
            <li key={`link${index}`} className={activeClass}>
                <a onClick={() => setTabIndex(index)} href={'/#'}>
                    <div className={'flex-1-1 display-flex align-items-center gap-10 padding-y-2'}>
                        <div className={'position-relative'}>
                            <span className={`text-size-h4 text-color-primary rioglyph ${tab.icon}`} />
                        </div>
                        <div className={'line-height-125rel'}>
                            <div className={'text-size-16'}>{tab.title}</div>
                            <div className={'text-size-12 text-color-dark'}>{tab.subTitle}</div>
                        </div>
                    </div>
                </a>
            </li>
        );
    };

    const renderTabLinks = (tabs: Array<Tab>, setTabIndex: any, tabIndex: any) => {
        return (
            <div className={'margin-bottom-10'}>
                <UserInNavbar />
                <ul className={'nav nav-pills nav-pills-stacked-decent nav-stacked'}>
                    {tabs.map((tab: any, index: any) => renderTabLink(tab, index, setTabIndex, tabIndex))}
                </ul>
            </div>
        );
    };

    return (
        <div className={'min-height-350'}>
            <div className={'UserSettingsBody container-fluid row padding-0'}>
                <div className={'display-flex flex-wrap justify-content-center gap-20'}>
                    <div className={'flex-1-1-0 min-width-300 navigation'}>
                        {renderTabLinks(tabs, setTabIndex, tabIndex)}
                    </div>
                    <div className={'flex-1-1 content'}>{tabs[tabIndex].content}</div>
                </div>
            </div>
        </div>
    );
};

export const UserSettingsDialog: FC = () => {
    const userProfileId = useAppSelector(getUserProfileId);

    const dispatch = useAppDispatch();

    const { isLoading } = useFetchUserQuery(userProfileId!);
    useFetchAccountsQuery(userProfileId!);

    const tabIndex = useAppSelector(selectTabIndex);

    const setTabIndex = (tabNumber: number) => {
        dispatch(selectTab(tabNumber));
    };

    const getTabItem = (headlineKey: string, subTitleKey: string, icon: string, content: ReactNode) => ({
        title: <FormattedMessage id={headlineKey} />,
        subTitle: <FormattedMessage id={subTitleKey} />,
        icon: icon,
        content: <div>{content}</div>,
    });

    const userMenuTabs: Array<Tab> = [
        getTabItem(
            'intl-msg:settingsDialog.tab.personalData.headline',
            'intl-msg:settingsDialog.tab.personalData.subTitle',
            'rioglyph-user',
            <PersonalData />
        ),
        getTabItem(
            'intl-msg:settingsDialog.tab.loginAndSecurity.headline',
            'intl-msg:settingsDialog.tab.loginAndSecurity.subTitle',
            'rioglyph-eye-close',
            <LoginAndSecurity />
        ),
        getTabItem(
            'intl-msg:settingsDialog.tab.accounts.headline',
            'intl-msg:settingsDialog.tab.accounts.subTitle',
            'rioglyph-addressbook',
            <Accounts />
        ),
        getTabItem(
            'intl-msg:settingsDialog.tab.legalInfo.headline',
            'intl-msg:settingsDialog.tab.legalInfo.subTitle',
            'rioglyph-info-sign',
            <LegalInfo />
        ),
    ];

    return isLoading ? (
        <div className={'min-height-350 display-flex'}>
            <div className={'container-fluid row padding-0'}>
                <Spinner isDoubleSized />
            </div>
        </div>
    ) : (
        <UserSettingsBody tabIndex={tabIndex} tabs={userMenuTabs} setTabIndex={setTabIndex} />
    );
};
