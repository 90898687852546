import { config } from '../../config';

export const configureRedirectToLogout = (window: Window) => (idToken?: string) => {
    const logoutBaseUri = config.logoutUri ?? 'https://auth.iam.rio.cloud/logout';
    const logoutUrl = new URL(logoutBaseUri);
    if (idToken) {
        logoutUrl.searchParams.append('id_token_hint', idToken);
    }
    window.location.href = logoutUrl.href;
};

export const redirectToLogout = configureRedirectToLogout(window);
