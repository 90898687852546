import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getLocale } from '../../../../configuration/lang/langSlice';

export const LegalInfo = () => {
    const userLocale = useAppSelector((state) => getLocale(state) ?? 'en-GB');
    const locale = getValidLocalePathSegmentFromLocale(userLocale);
    const legalLink = `https://rio.cloud/${locale}/legal`;
    const dataProtectionLink = `https://rio.cloud/${locale}/data-protection`;
    const imprintLink = `https://rio.cloud/${locale}/imprint`;
    return (
        <div className="max-width-300">
            <div className={'form-group'}>
                <label className="control-label">Cookie settings</label>
                <div>
                    <FormattedMessage id={'intl-msg:settingsDialog.tab.cookies.text'} />
                    <span> </span>
                    <a
                        href={`https://rio.cloud/${locale}/data-protection/cookies#c3322`}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                    >
                        <FormattedMessage id={'intl-msg:settingsDialog.tab.cookies.click.prompt'} />
                    </a>
                    .
                </div>
            </div>
            <div className={'form-group'}>
                <label className="control-label">Legal</label>
                <div data-cy={'legal-info-content'}>
                    <ul>
                        <li>
                            <a href={legalLink} target={'_blank'} rel={'noopener noreferrer'}>
                                <FormattedMessage id={'intl-msg:settingsDialog.tab.legalInfo.legal'} />
                            </a>
                        </li>
                        <li>
                            <a href={dataProtectionLink} target={'_blank'} rel={'noopener noreferrer'}>
                                <FormattedMessage id={'intl-msg:settingsDialog.tab.legalInfo.dataProtection'} />
                            </a>
                        </li>
                        <li>
                            <a href={imprintLink} target={'_blank'} rel={'noopener noreferrer'}>
                                <FormattedMessage id={'intl-msg:settingsDialog.tab.legalInfo.imprint'} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

const getValidLocalePathSegmentFromLocale = (locale: string) => {
    const fallbackLocale = 'en';
    const knownLocalesForLink = [
        'bg',
        'cz',
        'de',
        'dk',
        'ee',
        'en',
        'es',
        'fi',
        'fr',
        'gr',
        'hr',
        'hu',
        'it',
        'lt',
        'lv',
        'nl',
        'no',
        'pl',
        'pt',
        'ro',
        'se',
        'si',
        'sk',
    ];

    let localePrefix: string;

    switch (locale) {
        case 'nb-NO': {
            localePrefix = 'no';
            break;
        }
        default: {
            localePrefix = locale.split('-')[0];
        }
    }

    return knownLocalesForLink.includes(localePrefix) ? localePrefix : fallbackLocale;
};
