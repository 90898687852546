import { defaultMessages } from '../../../languageData';
import { IntlShape } from 'react-intl';
import { getSupportedLocaleForTenant } from '../../../../configuration/lang/lang';
import { LanguageDropdownItem, SupportedLocales } from '../../../../configuration/types';

const LOCALE_ID_PREFIX = 'intl-msg:settingsDialog.tab.language.locale.';

const sortLocales = (locales: SupportedLocales, messagesOfLocale: { [key: string]: string }) => {
    return locales
        .map((locale) => {
            return {
                locale: locale,
                displayName: messagesOfLocale[`${LOCALE_ID_PREFIX}${locale}`],
            };
        })
        .sort((a, b) => {
            const displayNameA = a.displayName || '';
            const displayNameB = b.displayName || '';

            /* eslint-disable no-console */
            console.assert(displayNameA, `translation missing for key: ${LOCALE_ID_PREFIX}${a.locale}`);
            console.assert(displayNameB, `translation missing for key: ${LOCALE_ID_PREFIX}${b.locale}`);

            return displayNameA.toLocaleLowerCase().localeCompare(displayNameB.toLocaleLowerCase());
        })
        .map((item) => item.locale);
};

export const getLanguageDropDownItems = (selectedLocale: string, tenant: string | undefined, intl: IntlShape) => {
    const options: LanguageDropdownItem[] = [];
    const locales = tenant ? [...getSupportedLocaleForTenant(tenant)] : [];
    const sortedLocales = sortLocales(locales, defaultMessages);
    sortedLocales.forEach((locale: string) => {
        options.push({
            id: locale,
            label: intl.formatMessage({ id: `intl-msg:settingsDialog.tab.language.locale.${locale}` }),
            selected: selectedLocale === locale,
        });
    });
    return options;
};
