import { IntlShape } from 'react-intl';

export const ERROR_NOT_EMPTY_ID = `intl-msg:inputFields.error.notEmpty`;

interface Validator {
    validate: (value: any) => boolean;
    message: any;
}

export function createValidationFunction(validationList: Validator[]) {
    return (value: any) => {
        const resultList = validationList
            .map((validator) => (validator.validate(value) ? null : validator.message))
            .filter((result) => result !== null);

        return resultList.length > 0 ? resultList[0] : null;
    };
}

export function isEmpty(value: any) {
    if (typeof value === 'string') {
        return value.trim() === '';
    }

    if (Array.isArray(value)) {
        return value.length === 0;
    }

    throw new Error('isEmpty: input not valid');
}

export function isNotEmpty(value: any) {
    return !isEmpty(value);
}

export function getNameValidation(intl: IntlShape) {
    return createValidationFunction([
        { validate: isNotEmpty, message: intl.formatMessage({ id: ERROR_NOT_EMPTY_ID }) },
    ]);
}
