import Spinner from '@rio-cloud/rio-uikit/Spinner';

export const isAppInMaintenanceMode = (maintenanceMode: string) => maintenanceMode === 'on';

export const CustomSpinner = () => (
    <div
        className={'col-lg-12 padding-25'}
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <Spinner text={'Loading'} isDoubleSized />
    </div>
);

export const generateRandomState = () => {
    const validChars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let array = new Uint8Array(32);
    window.crypto.getRandomValues(array);
    array = array.map((x) => validChars.charCodeAt(x % validChars.length));
    let jsonKey: string = '';
    array.forEach((byte: number) => {
        jsonKey += String.fromCharCode(byte);
    });
    return jsonKey;
};
