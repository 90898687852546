import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

export const ErrorStatusCodes = (props: { errors: (FetchBaseQueryError | SerializedError | undefined)[] }) => {
    const { errors } = props;
    const validErrors = errors.filter(isValidError);
    return validErrors.length > 0 ? <span>[{validErrors.map(errorDisplay)}]</span> : null;
};

const isValidError = (
    error: FetchBaseQueryError | SerializedError | undefined
): error is FetchBaseQueryError | SerializedError => {
    return error !== undefined;
};

const errorDisplay = (error: FetchBaseQueryError | SerializedError) => {
    if (isFetchBaseQueryError(error)) {
        return <span key={error.status}>{error.status}</span>;
    } else {
    }
};

const isFetchBaseQueryError = (error: FetchBaseQueryError | SerializedError): error is FetchBaseQueryError => {
    return 'status' in error;
};
