import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { getNameValidation } from '../../../common/validators';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextFormControl } from '../../../common/TextFormControl';
import { isMaintenanceMode } from '../../appSlice';
import { CustomSpinner } from '../../common';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import Select from '@rio-cloud/rio-uikit/Select';
import { getLanguageDropDownItems } from './languageHelpers';
import { SelectOption } from '@rio-cloud/rio-uikit';
import { getTenant } from '../../../../configuration/tokenHandling/tokenHandlingSlice';
import { useFetchUserQuery, usePatchUserMutation } from '../../services/usersApi';
import { getUserName, getUserProfileId } from '../../../../configuration/login/loginSlice';
import { sendLocaleChangeToEmbeddingComponent, sendUserInfoToEmbeddingComponent } from '../../../common/iFrameHelper';
import { displayMessagesFetched, localeChanged } from '../../../../configuration/lang/langSlice';
import { getLanguageData } from '../../../../configuration/lang/services';
import { ErrorBanner } from '../../common/ErrorBanner';
import { ErrorStatusCodes } from '../../common/ErrorStatusCodes';

export const PersonalData: React.FC = () => {
    const userName = useAppSelector(getUserName);
    const userProfileId = useAppSelector(getUserProfileId);
    const isInMaintenanceMode = useAppSelector(isMaintenanceMode);
    const tenant = useAppSelector(getTenant);

    const { data: user, isFetching, isLoading } = useFetchUserQuery(userProfileId!);
    const [updateUser, { isLoading: isUpdating, isError: isUpdateError }] = usePatchUserMutation();

    const intl = useIntl();
    const dispatch = useAppDispatch();

    const [firstName, setFirstName] = useState(user?.firstName);
    const [lastName, setLastName] = useState(user?.lastName);
    const [locale, setLocale] = useState(user?.preferredLanguage);

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setLocale(user.preferredLanguage);
        }
    }, [user]);

    const isNameChanged = (): boolean => {
        if (firstName === user?.firstName && lastName === user?.lastName) {
            return false;
        } else if (firstName?.trim() === '' || lastName?.trim() === '' || isInMaintenanceMode) {
            return false;
        }
        return true;
    };

    const isLanguageChanged = () => {
        return !(locale === user?.preferredLanguage || isInMaintenanceMode);
    };

    const isDisabled = !(isNameChanged() || isLanguageChanged()) || isFetching || isUpdating;

    const getLoadingClassname = () => {
        let className: string = '';
        if (isFetching || isUpdating) {
            className += ' btn-loading';
        }
        return className;
    };

    const onSaveButtonClick = () => {
        firstName &&
            lastName &&
            locale &&
            user &&
            updateUser({
                firstName,
                lastName,
                preferredLanguage: locale,
                id: user.id,
                accountId: user.accountId,
            })
                .unwrap()
                .then(() => (isLanguageChanged() ? getLanguageData(locale) : undefined))
                .then((languageData) => {
                    if (languageData) {
                        dispatch(localeChanged(locale));
                        dispatch(displayMessagesFetched({ locale, displayMessages: languageData }));
                        sendLocaleChangeToEmbeddingComponent(locale);
                    } else if (isNameChanged()) {
                        sendUserInfoToEmbeddingComponent(user, userName);
                    }
                })
                .catch(() => {
                    console.error('Error when updating user.');
                });
    };

    const renderSaveButton = () => (
        <div className={'btn-toolbar pull-right margin-top-20 margin-bottom-20'}>
            <button
                className={`btn btn-primary${getLoadingClassname()}`}
                onClick={onSaveButtonClick}
                data-cy={'personal-data-save-btn'}
                disabled={isDisabled}
            >
                <FormattedMessage id={'intl-msg:settingsDialog.tab.language.button.save.label'} />
            </button>
        </div>
    );

    const submitForm = () => {
        if (firstName && firstName.trim().length > 0 && lastName && lastName.trim().length > 0 && locale && user) {
            updateUser({
                firstName,
                lastName,
                preferredLanguage: locale,
                id: user.id,
                accountId: user.accountId,
            })
                .unwrap()
                .then(() => {
                    sendUserInfoToEmbeddingComponent(user, userName);
                });
        }
    };

    const onLanguageChange = (e: SelectOption | undefined) => setLocale(e?.id ?? 'en-GB');

    return isLoading || firstName === undefined || lastName === undefined || locale === undefined ? (
        <CustomSpinner />
    ) : (
        <div data-cy={'personal-data-content'}>
            {isUpdateError && (
                <div className="margin-bottom-10">
                    <ErrorBanner>
                        <strong className="text-size-16 margin-right-5">Error!</strong>
                        <ErrorStatusCodes errors={[]} />
                        <div>
                            <FormattedMessage
                                id={'intl-msg:settingsDialog.tab.personalData.error.updatePersonalData'}
                                defaultMessage={'Could not update user data. Please try again later.'}
                            />
                        </div>
                    </ErrorBanner>
                </div>
            )}
            <TextFormControl
                id={'intl-msg:settingsDialog.tab.personalData.firstNameInputField'}
                labelId={'intl-msg:settingsDialog.tab.personalData.label.firstName'}
                value={firstName}
                validateInput={getNameValidation(intl)}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                onEnter={submitForm}
                readOnly={isInMaintenanceMode}
                testId={'first-name-input'}
            />
            <TextFormControl
                id={'intl-msg:settingsDialog.tab.personalData.lastNameInputField'}
                labelId={'intl-msg:settingsDialog.tab.personalData.label.lastName'}
                value={lastName}
                validateInput={getNameValidation(intl)}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                onEnter={submitForm}
                readOnly={isInMaintenanceMode}
                testId={'last-name-input'}
            />
            <div className="form-group" data-cy={'language-content'}>
                <label className={'control-label'}>
                    <FormattedMessage id={'intl-msg:settingsDialog.tab.language.label.language'} />
                </label>
                <Select
                    className={'inputLanguage'}
                    dropdownClassName={'max-height-150'}
                    placeholder={<FormattedMessage id={'intl-msg:settingsDialog.tab.language.select.placeholder'} />}
                    onChange={onLanguageChange}
                    options={getLanguageDropDownItems(locale, tenant, intl)}
                    disabled={isInMaintenanceMode}
                />
            </div>

            {renderSaveButton()}
        </div>
    );
};
