import _defaultMessages from './translations/en-GB.json';
import { DEFAULT_LOCALE } from '../configuration/lang/lang';

export const defaultMessages = _defaultMessages;

export const FALLBACK_LOCALE = DEFAULT_LOCALE;

export function getDefaultLocale() {
    return FALLBACK_LOCALE;
}

const languageDataObjects = {
    [FALLBACK_LOCALE]: {
        locale: FALLBACK_LOCALE,
        localeShort: FALLBACK_LOCALE.substring(0, 2),
        messagesByLanguage: defaultMessages,
    },
};

export function addLanguageObject(locale, messages) {
    languageDataObjects[locale] = {
        locale: locale,
        localeShort: locale.substring(0, 2),
        messagesByLanguage: messages,
    };
}

export function getLanguageData(locale) {
    return (
        languageDataObjects[locale] || languageDataObjects[getDefaultLocale()] || languageDataObjects[FALLBACK_LOCALE]
    );
}

export function translate(id, locale = DEFAULT_LOCALE) {
    const languageData = getLanguageData(locale);
    return languageData.messagesByLanguage[id];
}
