interface UserExternal {
    id: string;
    account_id: string;
    additional_authentication_factors: string[] | null | undefined;
    first_name: string;
    last_name: string;
    email: string | null | undefined;
    phone_number: string | null | undefined;
    group_ids: string[] | null | undefined;
    preferred_login: string | null | undefined;
    preferred_language: string;
}

export interface User {
    id: string;
    accountId: string;
    additionalAuthenticationFactors: string[] | undefined;
    firstName: string;
    lastName: string;
    email: string | undefined;
    phoneNumber: string | undefined;
    groupIds: string[] | undefined;
    preferredLogin: string | undefined;
    preferredLanguage: string;
}

export type UserPatch = Partial<User> &
    Pick<User, 'id'> &
    Pick<User, 'accountId'> &
    Pick<User, 'firstName'> &
    Pick<User, 'lastName'> &
    Pick<User, 'preferredLanguage'>;

type UserPatchExternal = Partial<UserExternal> &
    Pick<UserExternal, 'id'> &
    Pick<UserExternal, 'account_id'> &
    Pick<UserExternal, 'first_name'> &
    Pick<UserExternal, 'last_name'> &
    Pick<UserExternal, 'preferred_language'>;

interface AccountExternal {
    id: string;
    name: string;
    active: boolean;
    lifecycle_state: string | null | undefined;
}

export interface Account {
    id: string;
    name: string;
    active: boolean;
    lifecycleState: string | undefined;
}

interface AccountApiResponse {
    id: string | null | undefined;
    accounts: AccountExternal[];
}

// type guards
function isObject(value: unknown): value is Record<string, unknown> {
    return typeof value === 'object' && value !== null;
}

const validateUserInput = (input: unknown): input is UserExternal => {
    if (!isObject(input)) {
        return false;
    }
    const requiredStringProps = ['id', 'account_id', 'first_name', 'last_name', 'preferred_language'];
    for (const prop of requiredStringProps) {
        if (typeof input[prop] !== 'string') {
            return false;
        }
    }

    const optionalStringProps = ['email', 'phone_number', 'preferred_login'];
    for (const prop of optionalStringProps) {
        if (input[prop] !== undefined && input[prop] !== null && typeof input[prop] !== 'string') {
            return false;
        }
    }

    const optionalArrayProps = ['additional_authentication_factors', 'group_ids'];
    for (const prop of optionalArrayProps) {
        if (input[prop] !== undefined && input[prop] !== null && !Array.isArray(input[prop])) {
            return false;
        }
    }
    return true;
};

const isAccountExternal = (input: unknown): input is AccountExternal =>
    typeof input === 'object' &&
    input !== null &&
    'id' in input &&
    typeof input.id === 'string' &&
    'name' in input &&
    typeof input.name === 'string' &&
    'active' in input &&
    typeof input.active === 'boolean' &&
    (('lifecycle_state' in input &&
        (typeof input.lifecycle_state === 'string' ||
            input.lifecycle_state === null ||
            input.lifecycle_state === undefined)) ||
        !('lifecycle_state' in input));

const isAccountApiResponse = (input: unknown): input is AccountApiResponse => {
    if (!isObject(input)) {
        return false;
    }

    if (!Array.isArray(input.accounts)) {
        return false;
    }

    return input.accounts.every(isAccountExternal);
};

// transformers
const validateString = (input: any): string => {
    if (typeof input !== 'string') {
        throw new Error('Invalid input type, expected string');
    }
    return input;
};

const validateNullableString = (input: any): string | undefined => {
    if (input === null || input === undefined) {
        return undefined;
    }
    return validateString(input);
};

const validateOptionalStringArray = (input: any): string[] | undefined => {
    if (input === null || input === undefined) {
        return undefined;
    }
    if (!Array.isArray(input)) {
        throw new Error('Invalid input type, expected string array');
    }
    input.forEach((item) => {
        if (typeof item !== 'string') {
            throw new Error('Invalid array element type, expected string');
        }
    });
    return input;
};

const validateBoolean = (input: any): boolean => {
    if (typeof input !== 'boolean') {
        throw new Error('Invalid input type, expected string');
    }
    return input;
};

export const transformUser = (input: unknown): User => {
    if (!validateUserInput(input)) {
        throw new Error('Invalid input for user data.');
    }
    return {
        id: validateString(input.id),
        accountId: validateString(input.account_id),
        additionalAuthenticationFactors: validateOptionalStringArray(input.additional_authentication_factors),
        firstName: validateString(input.first_name),
        lastName: validateString(input.last_name),
        email: validateNullableString(input.email),
        phoneNumber: validateNullableString(input.phone_number),
        groupIds: validateOptionalStringArray(input.group_ids),
        preferredLogin: validateNullableString(input.preferred_login),
        preferredLanguage: validateString(input.preferred_language),
    };
};

const transformAccount = (input: AccountExternal): Account => ({
    id: validateString(input.id),
    name: validateString(input.name),
    active: validateBoolean(input.active),
    lifecycleState: validateNullableString(input.lifecycle_state),
});

export const transformAccountApiResponse = (input: unknown) => {
    if (!isAccountApiResponse(input)) {
        throw new Error('Invalid input for account api response.');
    }

    return {
        id: validateNullableString(input.id),
        accounts: input.accounts.map(transformAccount),
    };
};

export const transformToExternalUserPatch = (user: UserPatch): UserPatchExternal => ({
    id: user.id,
    account_id: user.accountId,
    first_name: user.firstName,
    last_name: user.lastName,
    preferred_language: user.preferredLanguage,
});
