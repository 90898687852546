import 'iframe-resizer/js/iframeResizer.contentWindow';

import { Provider } from 'react-redux';
import { config } from './config';
import { main } from './configuration';
import { store } from './configuration/setup/store';
import { ErrorBoundary } from './features/common/ErrorBoundary';
import App from './features/App';
import { createRoot } from 'react-dom/client';
import handleLoginRedirect from './configuration/login/redirect';

const renderApplication = () => {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <App />
            </Provider>
        </ErrorBoundary>
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
