import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
    id: string;
    labelId: string;
    value: string;
    validateInput: (arg0: string) => string[];
    onInputChange: any;
    onEnter?: () => void;
    readOnly?: boolean;
    testId?: string;
}

const ENTER_KEY_CODE = 13;

export const TextFormControl = (props: Props) => {
    const { formatMessage } = useIntl();

    const [touched, setTouched] = useState(false);

    const validateInput = () => {
        const { validateInput, value } = props;
        let result = null;

        if (validateInput) {
            result = validateInput(value);
        }

        return result;
    };

    const getVisibleInputState = () => {
        let result = null;

        const externalResult = validateInput();
        if (touched) {
            result = externalResult;
        }

        return result === null ? null : 'has-error has-feedback';
    };

    const getVisibleError = () => {
        let result;

        const externalResult = validateInput();
        if (touched) {
            result = externalResult;
        }

        return result === null ? '' : result;
    };

    const onBlur = () => {
        setTouched(true);
    };

    const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === ENTER_KEY_CODE) {
            event.preventDefault();
            if (props.onEnter) {
                props.onEnter();
            }
        }
    };

    return (
        <>
            <form className={'form-horizontal'}>
                <fieldset>
                    <div className={`form-group ${getVisibleInputState()}`}>
                        <label htmlFor={props.id} className={'control-label'}>
                            <FormattedMessage id={props.labelId} defaultMessage={props.labelId} />
                        </label>
                        <input
                            type="text"
                            id={props.id}
                            placeholder={formatMessage({ id: props.labelId })}
                            onChange={(event) => props.onInputChange(event)}
                            onBlur={onBlur}
                            onKeyDown={onEnter}
                            value={props.value}
                            className={'form-control'}
                            disabled={props.readOnly}
                            data-cy={props.testId}
                        />
                        {getVisibleError() && (
                            <span className="help-block">
                                <span>{getVisibleError()}</span>
                            </span>
                        )}
                    </div>
                </fieldset>
            </form>
        </>
    );
};
